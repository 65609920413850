<template>
<div id="miel-read">
    <v-dialog scrollable v-model="dialog" persistent max-width="900" transition="dialog-top-transition">
        <v-card :loading="loading" :disabled="loading">
            <v-toolbar class="elevation-custom-table">
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Editar elemento</span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-img height="160" class="grey darken-3" v-if="miel.imagen.length == 0">
                <v-card-title class="align-center fill-height" primary-title>
                    <v-spacer></v-spacer>
                    <v-btn class="text-none" outlined color="grey darken-2" depressed @click="$refs.imageinput.$refs.input.click()">
                        <v-icon small left color="white">mdi-paperclip</v-icon>
                        <span class="white--text">Seleccionar imagenes</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-title>
            </v-img>
            <v-file-input accept="image/png, image/jpeg, image/webp" multiple v-model="imagenes" truncate-length="15" ref="imageinput" class="d-none" @change="uploadImage"></v-file-input>
            <v-carousel :show-arrows="false" hide-delimiters v-model="model" height="auto" v-if="miel.imagen.length > 0">
                 <v-carousel-item v-for="imagen in this.miel.imagen" :key="imagen" contain>
                    <v-img height="300" max-height="300" :src="parseImage(imagen)" class="grey darken-4" contain>
                    </v-img>
                </v-carousel-item>
            </v-carousel>
            <v-toolbar flat color="grey darken-3" class="px-2" v-if="miel.imagen.length > 0">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="$refs.imageinput.$refs.input.click()" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-paperclip-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar imágenes</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="model--" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-chevron-left</v-icon>
                        </v-btn>
                    </template>
                    <span>Anterior</span>
                </v-tooltip>
                <span class="white--text body-2 font-weight-bold">{{model+1}} / {{this.miel.imagen.length}}</span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="model++" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-btn>
                    </template>
                    <span>Siguiente</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="deleteImage(miel.imagen[model])" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Remover imagen</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-text class="pt-5">
                <v-row>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.tipoDeMiel" color="primary" label="Tipo de miel" persistent-placeholder hide-details>
                            <template #label>
                                Tipo de miel<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.nombre" label="Nombre" color="primary" persistent-placeholder hide-details>
                            <template #label>
                                Nombre<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.origenBotanico" label="Origen botánico" color="primary" persistent-placeholder hide-details>
                            <template #label>
                                Origen botánico<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.granosDePolen" label="Granos de polen" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.mielDeAbeja" label="Miel de abeja" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.tipoDeVegetacion" label="Tipo de vegetación" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.usos" label="Usos" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="miel.fechaDeRecoleccion" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :value="formatDate(miel.fechaDeRecoleccion)" label="Fecha de recolección" persistent-placeholder readonly v-bind="attrs" v-on="on" hide-details></v-text-field>
                            </template>
                            <v-date-picker v-model="miel.fechaDeRecoleccion" no-title scrollable color="primary">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false" class="text-none">
                                    Cancelar
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(miel.fechaDeRecoleccion)" class="text-none">
                                    Aceptar
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.estado" label="Estado" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.municipio" label="Municipio" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.nombreDelRecolector" label="Nombre de el recolector" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.nombreDelApicultor" label="Nombre de el apicultor" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field v-model="miel.donante" label="Donante" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.claveDeColeccion" label="Clave de colección" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.publicacionAsociada" label="Publicación asociada" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-2">
                        <v-text-field v-model="miel.proyecto" label="Proyecto" color="primary" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-row>
                    <v-spacer></v-spacer>
                    <vue-qr :text="'3_'+miel._id"></vue-qr>
                    <v-spacer></v-spacer>
                </v-row>
                </v-row>
            </v-card-text>
             <v-card-actions>
                <v-btn class="text-none" color="red" text @click="confirm = true">
                    Eliminar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="text-none" color="grey darken-2" text @click="$router.push('/mieles')">
                    Cerrar
                </v-btn>
                <v-btn class="text-none" color="green" text @click="update">
                    Actualizar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="confirm" persistent max-width="300" transition="dialog-top-transition">
        <v-card color>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <span class="font-weight-bold red--text">
                    Advertencia
                </span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="text-center">
                Se eliminara este elemento y no podra recuperarse, ¿deseas continuar?
            </v-card-text>
            <v-card-actions>
                <v-btn text class="text-none" color="grey darken-1" @click="confirm = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn text class="text-none" color="red" @click="destroy()">Eliminar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import VueQr from 'vue-qr';
export default {
    name: 'mielStore',
    components: {
        VueQr
    },
    data: () => ({
        model: 0,
        size: 300,
        loading: false,
        confirm: false,
        dialog: true,
        imagenes: null,
        miel: {
            tipoDeMiel: null,
            nombre: null,
            origenBotanico: null,
            granosDePolen: null,
            mielDeAbeja: null,
            tipoDeVegetacion: null,
            usos: null,
            fechaDeRecoleccion: null,
            estado: null,
            municipio: null,
            nombreDelApicultor: null,
            nombreDelRecolector: null,
            donante: null,
            claveColeccion: null,
            publicacionAsociada: null,
            proyecto: null,
            imagen: []
        },
        months: [
            { value: 'Enero' },
            { value: 'Febrero' },
            { value: 'Marzo' },
            { value: 'Abril' },
            { value: 'Mayo' },
            { value: 'Junio' },
            { value: 'Julio' },
            { value: 'Agosto' },
            { value: 'Septiembre' },
            { value: 'Octubre' },
            { value: 'Noviembre' },
            { value: 'Diciembre' },
        ]
    }),
    methods: {
        async uploadImage(){
            this.loading = true;
            const form = new FormData();
            if(this.imagenes != null){
                this.imagenes.forEach((imagen) => {
                    form.append('files', imagen);
                });
            }
            await this.$axios.post('/api/miel/' + this.miel._id + '/upload', form).then(response => {
                if(response.status == 201){
                    response.data.forEach(imagen => {
                        this.miel.imagen.push(imagen);
                    })
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.imagenes = null;
                this.loading = false;
            })
        },
        async deleteImage(image){
            this.loading = true;
            await this.$axios.delete('/api/miel/'+this.miel._id+'/remove/'+image).then(response => {
                if(response.status == 204){
                    let index = this.miel.imagen.findIndex(imagen => imagen == image);
                    this.miel.imagen.splice(index, 1);
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            })
        },
        parseImage(image) {
            return this.path + 'uploads/' + image;
        },
        formatDate(date) {
            if (date != null) {
                const dateWithTime = date.split('T');
                const dateOnly = dateWithTime[0].split('-');
                const dateObject = new Date(dateOnly[0], dateOnly[1] - 1, dateOnly[2]);
                return dateObject.toLocaleDateString('es-MX');
            }
        },
        async update() {
            this.loading = true;
            await this.$axios.put('/api/miel/' + this.miel._id, this.miel).then(response => {
                if(response.status == 200){
                    this.$store.commit('miel/UPDATE_MIEL', response.data);
                    this.$store.commit('snackbar/SHOW_SNACKBAR', { icon: 'mdi-check', message: 'Elemento actualizado' });
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            });
        },
        async destroy() {
            this.loading = true;
            await this.$axios.delete('/api/miel/' + this.miel._id).then(response => {
                if (response.status == 204) {
                    this.$store.commit('miel/DELETE_MIEL', this.miel._id);
                    this.$store.commit('snackbar/SHOW_SNACKBAR', { icon: 'mdi-check', message: 'Elemento eliminado' });
                    this.$router.push('/mieles');
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            })
        },
        async read() {
            this.loading = true;
            await this.$axios.get('/api/miel/' + this.$route.params.id).then(response => {
                if (response.status == 200) {
                    this.miel = response.data;
                    this.mielUntouched = JSON.stringify(response.data);
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.read();
    },
    props: {
        height: Number
    },
    computed: {
        path() {
            return process.env.VUE_APP_BASE_URL;
        }
    }
}
</script>
